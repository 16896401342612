import { useCreate, useNotify, useRedirect } from "react-admin"

const RESOURCE = "calendar_entry_statuses"

export const useStatusUpdate = (recordId: string | number) => {
  const notify = useNotify()
  const redirectTo = useRedirect()

  const [create, { isLoading }] = useCreate(
    RESOURCE,
    {
      data: {
        calendar_entry_id: recordId,
        status: "", // Will be set when called
      },
    },
    {
      onSuccess: () => {
        notify("Status Updated")
        redirectTo(`/calendar_entries/${recordId}`)
      },
    },
  )

  const updateStatus = (status: string) => {
    create(RESOURCE, { data: { calendar_entry_id: recordId, status } })
  }

  return { updateStatus, isLoading }
}
