import ThumbDown from "@mui/icons-material/ThumbDown"
import PropTypes from "prop-types"
import React from "react"
import { Button, Confirm, useRecordContext } from "react-admin"

import { useStatusUpdate } from "../hooks/useStatusUpdate"

const CancelButton = () => {
  const record = useRecordContext()
  const [open, setOpen] = React.useState(false)
  const { updateStatus, isLoading } = useStatusUpdate(record.id)

  if (!record) return null

  const handleClick = () => setOpen(true)
  const handleDialogClose = () => setOpen(false)
  const handleConfirm = () => {
    updateStatus("cancelled")
    setOpen(false)
  }

  return record && ["confirmed"].includes(record.current_status) ? (
    <>
      <Button
        variant="outlined"
        color="secondary"
        size="small"
        label="Cancel Booking"
        startIcon={<ThumbDown color="primary" style={{ paddingRight: "0.5em", color: "orange" }} />}
        onClick={handleClick}
        disabled={isLoading}
      />
      <Confirm
        isOpen={open}
        loading={isLoading}
        title="Cancel Booking"
        content="Are you sure you want to CANCEL the booking?"
        cancel="No"
        confirm="Yes"
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </>
  ) : (
    <span />
  )
}

CancelButton.propTypes = {
  record: PropTypes.any,
}

export default CancelButton
