import ThumbDown from "@mui/icons-material/ThumbDown"
import PropTypes from "prop-types"
import React from "react"
import { Button, Confirm, useRecordContext } from "react-admin"
import { useStatusUpdate } from "../hooks/useStatusUpdate"

const DeclineButton = () => {
  const record = useRecordContext()
  const [open, setOpen] = React.useState(false)
  const { updateStatus, isLoading } = useStatusUpdate(record.id)
  if (!record) return null

  const handleClick = () => setOpen(true)
  const handleDialogClose = () => setOpen(false)
  const handleConfirm = () => {
    updateStatus("declined")
    setOpen(false)
  }

  return record &&
    [
      "requested",
      // "confirmed"
    ].includes(record.current_status) ? (
    <>
      <Button
        variant="outlined"
        color="primary"
        size="small"
        label="Decline Booking"
        startIcon={<ThumbDown color="primary" style={{ paddingRight: "0.5em", color: "red" }} />}
        onClick={handleClick}
        disabled={isLoading}
      />
      <Confirm
        isOpen={open}
        loading={isLoading}
        title="Decline Booking"
        content="Are you sure you want to DECLINE the booking?"
        cancel="No"
        confirm="Yes"
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </>
  ) : (
    <span />
  )
}

DeclineButton.propTypes = {
  record: PropTypes.any,
}

export default DeclineButton
