import { AutocompleteInput, AutocompleteInputProps, ReferenceInput } from "react-admin"

interface Props extends Omit<AutocompleteInputProps, "source"> {
  source?: string
}

const UserRefInput = (props: Props) => {
  const filterToQuery = (searchText: string) => ({ search: searchText })
  console.log(props)
  const { source = "user_id", ...rest } = props
  return (
    <ReferenceInput reference="users" source={source} perPage={100}>
      <AutocompleteInput
        filterToQuery={filterToQuery}
        optionText="display_name"
        debounce={500}
        {...rest}
      />
    </ReferenceInput>
  )
}

export { UserRefInput }
