import { SelectInput, SelectInputProps } from "react-admin"

export const LanguageChoices = [
  { id: "EN", name: "English" },
  { id: "DE", name: "German" },
]

export const SelectLanguageInput: React.FC<SelectInputProps> = (props) => (
  <SelectInput emptyValue={undefined} resettable {...props} choices={LanguageChoices} />
)
