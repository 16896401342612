import ThumbUp from "@mui/icons-material/ThumbUp"
import PropTypes from "prop-types"
import React from "react"
import { Button, Confirm, useNotify, useRecordContext, useRedirect } from "react-admin"
import { useStatusUpdate } from "../hooks/useStatusUpdate"

const ConfirmButton = () => {
  const record = useRecordContext()
  const [open, setOpen] = React.useState(false)
  const { updateStatus, isLoading } = useStatusUpdate(record.id)

  if (!record) return null

  const handleClick = () => setOpen(true)
  const handleDialogClose = () => setOpen(false)
  const handleConfirm = () => {
    updateStatus("confirmed")
    setOpen(false)
  }

  return record && record.current_status === "requested" ? (
    <>
      <Button
        variant="outlined"
        color="primary"
        size="small"
        label="Confirm Booking"
        startIcon={<ThumbUp color="primary" style={{ paddingRight: "0.5em", color: "green" }} />}
        onClick={handleClick}
        disabled={isLoading}
      />
      <Confirm
        isOpen={open}
        loading={isLoading}
        title="Confirm Booking"
        content="Are you sure you want to CONFIRM the booking?"
        cancel="No"
        confirm="Yes"
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </>
  ) : (
    <span />
  )
}

ConfirmButton.propTypes = {
  record: PropTypes.any,
}

export default ConfirmButton
